(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/product-classname/assets/javascripts/product-classname.js') >= 0) return;  svs.modules.push('/components/lb-utils/product-classname/assets/javascripts/product-classname.js');
"use strict";


let products;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  products = trinidad.components.require('utils', 'products');
} else {
  products = svs.utils.products;
}

const resolveProductClassName = productId => {
  switch (productId) {
    case 1001:
      return 'casino';
    case 16: 
    case 71: 
    case 72: 
    case 73: 
    case 74: 
    case 75: 
    case 76:
      return 'bingo';
    case 80:
      return 'poker';
    default:
      return products.getProductClassName(productId);
  }
};
if (svs.isServer) {
  module.exports = {
    resolveProductClassName
  };
} else {
  setGlobal('svs.components.lbUtils.productClassname', {
    resolveProductClassName
  });
}

 })(window);